<template>
  <div>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <div>
              <b-card-title>รายละเอียด</b-card-title>
            </div>
          </b-card-header>
          <hr class="m-0">
          <b-card-body>
            <b-form-group
              label="รอบที่เรียกเก็บ"
              label-for="รอบที่เรียกเก็บ"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="รอบที่เรียกเก็บ"
                :value="roundDate"
                class="text-right text-white font-weight-bolder"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ค่าธรรมเนียมในรอบ"
              label-for="ค่าธรรมเนียมในรอบ"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="ค่าธรรมเนียมในรอบ"
                :value="invoiceDetail.totalFee | currency"
                class="text-right text-white font-weight-bolder"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="% ที่เรียกเก็บ"
              label-for="% ที่เรียกเก็บ"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="% ที่เรียกเก็บ"
                :value="`${invoiceDetail.commissionRate} %`"
                class="text-right text-white font-weight-bolder"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ยอดที่เรียกเก็บ"
              label-for="ยอดที่เรียกเก็บ"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="ยอดที่เรียกเก็บ"
                :value="invoiceDetail.outstanding | currency"
                class="text-right text-white font-weight-bolder"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ยอดค้างชำระ"
              label-for="ยอดค้างชำระ"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="ยอดค้างชำระ"
                :value="invoiceDetail.totalAmount | currency"
                class="text-right font-weight-bolder"
                :class="invoiceDetail.totalAmount> 0? 'text-danger': 'text-success'"
                plaintext
              />
            </b-form-group>
            <b-form-group
              label="ครบกำหนดชำระ"
              label-for="ครบกำหนดชำระ"
              label-cols="6"
              label-class="text-secondary"
            >
              <b-form-input
                id="ครบกำหนดชำระ"
                :value="invoiceDetail.dueDate | date"
                class="text-right text-white font-weight-bolder"
                plaintext
              />
            </b-form-group>
          </b-card-body> 
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <DownLinesTable />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    DownLinesTable: () => import('./DownLinesTable.vue'),
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.payment.isGettingInvoiceDetail,
    }),
    // ...mapGetters(['invoiceDetail']),
    invoiceId() {
      const { query } = this.$route
      return query.id ? query.id : ''
    },
    invoiceDetail() {
      return {
				ref: '',
				startDate: new Date(),
				dueDate: new Date(),
				endDate: new Date(),
				totalFee: 0,
				commissionRate: '0.9',
        totalAmount: 0,
        outstanding: 0,
      }
    },
		roundDate() {
			const {startDate, endDate} = this.invoiceDetail;
			if(startDate && endDate) {
				return `${this.$date(startDate)} - ${this.$date(endDate)}`
			}
			return ''
		},
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['']),
    fetchData() {
    },
  },
}
</script>
